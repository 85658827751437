import { CountryHelper, SessionHelper } from '@helpers';
import { IMarketplace, IMarketplacesByCountry } from '@interfaces';

const WEBSITE: string = 'website';
const OLD_WEBSITE: string = 'magento';
const RETAIL: string = 'retail';
const CASTORAMA: string = 'castorama';
const ALLEGRO: string = 'allegro';
const AG_MARKETPLACES: string[] = [WEBSITE, OLD_WEBSITE];
const ATTRIBUTE_EDITABLE_MARKETPLACES: string[] = [CASTORAMA, ALLEGRO];

export class MarketplaceHelper {

  /**
   * Gets website marketplace
   *
   * @returns {any}
   */
  static getWebsiteMarketplace(): any {
    const marketplaces = SessionHelper.getMarketplaces();

    if (marketplaces) {
      return marketplaces.find((item) => WEBSITE === item.parent.code);
    }

    return undefined;
  }

  /**
   * Get Alice's garden marketplace (magento or website)
   *
   * @returns {any}
   */
  static getAlicesGardenMarketplace(): any {
    const marketplaces = SessionHelper.getMarketplaces();

    if (marketplaces) {
      return marketplaces.find((item) => AG_MARKETPLACES.includes(item.parent.code));
    }

    return undefined;
  }

  /**
   * Checks if the current session has a website marketplace
   *
   * @returns {boolean}
   */
  static hasWebsiteMarketplace(): boolean {
    return 'undefined' !== typeof MarketplaceHelper.getWebsiteMarketplace();
  }

  /**
   * Checks if a marketplace is a website marketplace
   *
   * @param {any} marketplace
   * @returns {boolean}
   */
  static isWebsiteMarketplace(marketplace: any): boolean {
    if (typeof marketplace === 'object' && marketplace.marketplaceGroup && marketplace.marketplaceGroup.code) {
      return WEBSITE === marketplace.marketplaceGroup.code;
    }

    return WEBSITE === MarketplaceHelper.getRootMarketplaceCode(marketplace);
  }

  /**
   * Checks if a marketplace is a retail marketplace
   *
   * @param {any} marketplace
   * @returns {boolean}
   */
  static isRetailMarketplace(marketplace: any): boolean {
    if (typeof marketplace === 'object' && marketplace.marketplaceGroup && marketplace.marketplaceGroup.code) {
      return RETAIL === marketplace.marketplaceGroup.code;
    }

    return RETAIL === MarketplaceHelper.getRootMarketplaceCode(marketplace);
  }

  /**
   * Checks if a marketplace is an Alice's garden marketplace
   *
   * @param {any} marketplace
   * @returns {boolean}
   */
  static isAlicesGardenMarketplace(marketplace: any): boolean {
    return AG_MARKETPLACES.includes(MarketplaceHelper.getRootMarketplaceCode(marketplace));
  }

  /**
   * Checks if a marketplace is allow to edit category/attribute
   *
   * @param {any} marketplace
   * @returns {boolean}
   */
  static isAttributeEditableMarketplace(marketplace: any): boolean {
      return ATTRIBUTE_EDITABLE_MARKETPLACES.includes(
          MarketplaceHelper.getRootMarketplaceCode(marketplace)
      );
  }

  /**
   * Gets the code of the root marketplace
   *
   * @param {any} marketplace
   * @returns {any}
   */
  static getRootMarketplaceCode(marketplace: any): any {
    if (undefined === marketplace || undefined === marketplace.parent || undefined === marketplace.parent.code) {
      return undefined;
    }

    return marketplace.parent.code;
  }

  /**
   * Gets marketplaces for current session to display on product page
   */
  static getDisplayedOnProductPageMarketplaces(): IMarketplace[] {
    return SessionHelper.getMarketplaces().filter((marketplace: IMarketplace) => marketplace.handleProductFlow);
  }

  /**
   * Gets filtered marketplae for current session to display on products list page.
   */
  static getDisplayedOnProductListPageFilteredMarketplaces(): IMarketplace[] {
    const filtered = SessionHelper.getFilteredMarketplaces();
    const result: IMarketplace[] = [];

    if (null === filtered) {
      return this.getDisplayedOnProductPageMarketplaces();
    }

    Object.keys(filtered).map((countryCode: string) => {
      const marketplaces: IMarketplace[] = filtered[countryCode];
      marketplaces.map((marketplace: IMarketplace) => {
        if (!marketplace.handleProductFlow) {
          return;
        }

        marketplace.country = CountryHelper.getCountryByCountryCode(countryCode);

        result.push(marketplace);
      });
    });

    return result.length > 0 ? result : this.getDisplayedOnProductPageMarketplaces();
  }

  static getDisplayedOnProductListPageFilteredMarketplaceCodes(): string[] {
    return MarketplaceHelper.getDisplayedOnProductListPageFilteredMarketplaces().map((marketplace: IMarketplace) => marketplace.code);
  }

  /**
   * Gets a marketplace as an object from a code.
   */
  static getMarketplaceByCode(code: string): IMarketplace {
    return SessionHelper.getAllMarketplaces().find((marketplace: IMarketplace) => marketplace.code === code);
  }

  /**
   * Checks if a marketplace is filtered on products list page.
   */
  static isFilteredOnProductListPage(code: string, countryCode: string): boolean {
    const filteredMarketplaces = SessionHelper.getFilteredMarketplaces();

    if (null === filteredMarketplaces || undefined === filteredMarketplaces[countryCode]) {
      return false;
    }

    const find = filteredMarketplaces[countryCode].find((marketplace: IMarketplace) => {
      return marketplace.code === code;
    });

    return undefined !== find;
  }

  static getMarketplacesByCountry(handleProductFlow: boolean = true): IMarketplacesByCountry {
    const marketplacesByCountry: IMarketplacesByCountry = SessionHelper.getMarketplacesByCountry();

    if (handleProductFlow) {
      const result: IMarketplacesByCountry = {};
      Object.keys(marketplacesByCountry).map((countryCode: string) => {
        result[countryCode] = marketplacesByCountry[countryCode].filter((marketplace: IMarketplace) => marketplace.handleProductFlow);
      });

      return result;
    }

    return marketplacesByCountry;
  }
}
