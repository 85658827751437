import {Component, Inject, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { AuthService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource } from '@resources';
import { ChatResource } from '@components/chat/chat.resource';
import { SessionHelper } from '@helpers';
import { IChat } from '@components/chat/chat.interface';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { MERCURE_URL } from '@constants';

@Component({
  selector: 'app-chat',
  template: require('./chat.component.html'),
  styles: [require('./chat.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: ChatResource }
  ]
})

export class ChatComponent extends AbstractComponent implements AfterViewChecked {
  @ViewChild('chatMoniqueContainer') chatMoniqueContainer!: ElementRef;
  @ViewChild('chatGptContainer') chatGptContainer!: ElementRef;

  moniqueNewMessage: string = '';
  chatGptNewMessage: string = '';
  moniqueMessages: IChat[] = [];
  chatGptMessages: IChat[] = [];
  activeTab: string = 'monique';
  isUser: boolean = true;
  threadId: string = '';

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    if (this.chatMoniqueContainer) {
      this.chatMoniqueContainer.nativeElement.scrollTop = this.chatMoniqueContainer.nativeElement.scrollHeight;
    } else if (this.chatGptContainer) {
      this.chatGptContainer.nativeElement.scrollTop = this.chatGptContainer.nativeElement.scrollHeight;
    }
  }

  public toggleChat() {
    const displayed = document.getElementById('app-chat').style.display.includes('block');
    document.getElementById('app-chat').style.display = displayed ? 'none' : 'block';
  }

  public setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  public clearChat() {
    if (this.activeTab === 'monique') {
      this.moniqueMessages = [];
      localStorage.removeItem('MONIQUE_THREAD_ID');
      this.isUser = true;
    } else if (this.activeTab === 'chatGpt') {
      this.chatGptMessages = [];
      localStorage.removeItem('CHATBOT_THREAD_ID');
      this.isUser = true;
    }
  }

  public sendMessage(): void {
    const newMessage = this.getNewMessage();

    if (!newMessage) {
      return;
    }

    const conversation = this.getConversation();
    if (!conversation) {
      return;
    }
    this.updateMessages(conversation, newMessage);
    this.resetNewMessage();
  }

  private getNewMessage(): string | null {
    return this.activeTab === 'monique' ? this.moniqueNewMessage.trim() :
      this.activeTab === 'chatGpt' ? this.chatGptNewMessage.trim() : null;
  }

  private getConversation(): any[] | null {
    return this.activeTab === 'monique' ? this.moniqueMessages :
      this.activeTab === 'chatGpt' ? this.chatGptMessages : null;
  }

  private resetNewMessage(): void {
    if (this.activeTab === 'monique') {
      this.moniqueNewMessage = '';
    } else if (this.activeTab === 'chatGpt') {
      this.chatGptNewMessage = '';
    }
  }

  public updateMessages(conversation: any[], newMessage: string): void {
    const threadIdKey: string = this.getThreadIdKey();
    this.threadId = localStorage.getItem(threadIdKey) || '';

    conversation.push({threadId: this.threadId, text: newMessage, isUser: true });
    this.isUser = false;

    this.send(newMessage);
    this.subscribeToMercure(conversation, threadIdKey);

    conversation.push({
      threadId: this.threadId,
      text: this.activeTab === 'monique' ? this.translate('CHAT.MONIQUE_WRITING') : this.translate('CHAT.CHATGPT_WRITING'),
      isUser: false
    });
  }

  private getThreadIdKey(): string {
    return this.activeTab === 'monique' ? 'MONIQUE_THREAD_ID' : 'CHATBOT_THREAD_ID';
  }

  private subscribeToMercure(conversation: any[], threadIdKey: string): void {
    const mercureUrl = MERCURE_URL + '?topic=' +
      encodeURIComponent('https://api.sweeek.io/api/v2/chat/send-message/' + SessionHelper.getUser().username);

    const mercure = new EventSourcePolyfill(mercureUrl);

    mercure.onmessage = (event: any) => {
      const data = JSON.parse(event.data);

      this.syncHistory(data, conversation);

      conversation.pop();
      conversation.push({ threadId: this.threadId, text: data.response, isUser: false });

      if (!localStorage.getItem(threadIdKey)) {
        localStorage.setItem(threadIdKey, data.threadId);
      }

      this.isUser = true;
    };
  }

  private syncHistory(data: any, conversation: any[]): void {
    if (data.history.length !== conversation.length) {
      const updatedMessages = data.history.map((item: any) => ({
        text: item.message,
        threadId: this.threadId,
        isUser: item.role === 'user'
      }));

      if (this.activeTab === 'monique') {
        this.moniqueMessages = updatedMessages;
      } else {
        this.chatGptMessages = updatedMessages;
      }
    }
  }

  public send(message: string): void {
    this.resource.create({
      threadId: this.threadId,
      author: SessionHelper.getUser().username,
      message,
      assistant: this.activeTab
    }).subscribe();
  }
}
