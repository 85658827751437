'use strict'

import './jquery-bootstrap'
window.Selectize = require('selectize')
window.daterangepicker = require 'bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import 'angular'
import 'zone.js'
import 'reflect-metadata'
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static'

import {
  AttributeListComponent,
  AttributeSetListComponent,
  AttributeTableComponent,
  AttributeEditComponent,
  AttributeNewComponent,
  AttributeFamilyEditComponent,
  AttributeSetComponent,
  BannerListComponent,
  BannerFormComponent,
  BannerFormTranslationComponent,
  CarrierGroupListComponent,
  CarrierGroupListItemComponent,
  CarrierFamilyListComponent,
  CarrierFamilyNewComponent,
  CarrierFamilyShippingPriceComponent,
  CarrierFamilyResource,
  CarrierFamilyEditComponent,
  CarrierFamilyResultList,
  CatalogsListComponent,
  CatalogsComponent,
  CategoriesComponent,
  ChatComponent,
  CMSListComponent,
  CMSFormComponent,
  CommercialOperationsListComponent,
  CustomerListComponent,
  CustomerPhotosComponent,
  UserGuideComponent,
  AutomaticDiscountListComponent,
  AutomaticDiscountResultListComponent,
  AutomaticDiscountFormComponent
  DiscountListComponent,
  DiscountResultListComponent,
  DiscountFormComponent,
  FormControlComponent,
  FormComponent,
  WebsiteHomepageHighlightsItemsComponent,
  WebsiteHomepageHighlightsItemsListComponent,
  WebsiteHomepageHighlightsItemsResultListComponent,
  ManufacturerListComponent,
  PaymentCarrierIconsComponent,
  RedirectListComponent,
  RefundsListComponent,
  ReverseListComponent,
  SeoFormComponent,
  CategoriesSatelliteFormComponent,
  CategoryBlocksFormComponent,
  ServiceFormComponent,
  ServiceResultListComponent,
  ServiceListComponent,
  ShippingPriceListComponent,
  ShippingPriceFormComponent,
  StockImportListComponent,
  SuperProductsFormComponent,
  SuperProductListComponent,
  ImportSuperProductComponent,
  SuperProductComponent,
  SuperProductContentWebsiteComponent,
  SuperProductFormTranslationsComponent,
  OrderResultListComponent,
  OrderResultListTrComponent,
  OrderRefundComponent,
  OrderCreationComponent,
  OrderManagerFormComponent,
  OrderManagerScalingFormComponent,
  OrderManagerScalingListComponent,
  OrderManagerSageExportListComponent,
  OrderManagerSageExportResultListComponent,
  DelayReasonOrderListComponent,
  DelayReasonOrderResultListComponent,
  ProductPageComponent,
  ProductLightPageComponent,
  SparePartPageComponent,
  SparePartPageWarehouseAgentComponent,
  SparePartSearchComponent,
  ProductStatusComponent,
  ProductStatusEditableComponent,
  ProductCarrierFormComponent,
  ImportProductComponent,
  ImportProductFieldsComponent
  ProductLogisticFormComponent,
  ProductGeneralFormComponent,
  SparePartProductGeneralFormComponent,
  ProductContentTabComponent,
  ProductContentFormComponent,
  ProductContentFormTranslationsComponent,
  ProductAttributesFormComponent,
  ProductPicturesFormComponent,
  ProductProductFormComponent,
  ProductProductAvailabilityFormComponent,
  ProductSageFormComponent,
  ProductVersionsFormComponent,
  ProductMarketplaceFormsComponent,
  ProductMarketplaceGeneralFormComponent,
  ProductWebsiteMarketplaceFormComponent,
  DuplicateProductComponent,
  ProductListComponent,
  PurchaseServiceFormComponent,
  LogisticComponent,
  TabComponent,
  TabLinkedProductsComponent,
  ProductContentWebsiteComponent,
  RedirectsComponent,
  FooterLinksListComponent,
  FooterLinksResultListComponent,
  ReinsurancePointsListComponent,
  ReinsurancePointsResultListComponent,
  ReinsurancePointsComponent,
  UnpairedListComponent,
  WarehouseListComponent,
  WarehousesEditComponent,
  WarrantyListComponent,
  WebsiteCategoryListComponent,
  WebsiteCategoryResultListComponent,
  WebsiteCategoryFormComponent,
  WebsiteModuleComponent,
  WebsiteHomepageAdvicesComponent,
  WebsiteHomepageHighlightsComponent,
  WebsiteHomepageBargainsComponent,
  WebsiteHomepageSliderComponent,
  WebsiteHomepageSeoComponent,
  WebsiteHomepageSliderItemsComponent,
  WebsiteHomepageSliderItemsListComponent,
  WebsiteHomepageSliderItemsResultListComponent,
  AddressingListComponent,
  LitigationListComponent,
  LitigationResultListComponent,
  LogisticWaveListComponent,
  LogisticWaveResultListComponent,
  OneyOpcListComponent,
  OneyOpcFormComponent,
  EavAttributeFormComponent,
  EavAttributeListComponent,
  GlossaryFormComponent,
  GlossaryListComponent,
  SupplierListComponent,
  SupplierFormComponent,
  ProformaInvoiceListComponent,
  ProformaInvoiceFormComponent,
  ProformaInvoiceImportComponent,
  ContainerFormComponent,
  ContainerListComponent
} from '@components'
import { ButtonActionMultipleComponent } from '@components/generic/buttons'
import { BreadcrumbsComponent } from "@components/partials/breadcrumbs"
import { CategoriesMarketplaceListComponent } from '@components/categories-marketplace/list'
import { CommercialOperationsComponent } from './components/commercial-operations/commercial-operations.component'
import { CommercialOperationsProductsComponent } from './components/commercial-operations/commercial-operations-products.component'
import { CommercialOperationsSeoComponent } from './components/commercial-operations/commercial-operations-seo.component'
import { CommercialOperationsBlockListComponent } from './components/commercial-operations/commercial-operations-block-list.component'
import { CommercialOperationsBlockResultListComponent } from './components/commercial-operations/commercial-operations-block-result-list.component'
import { CategoriesListComponent, CategoriesSatelliteListComponent, CategoriesSatelliteResultListComponent } from "@components/categories/list"

import {
  CustomerDetailComponent,
  CustomerDetailAddressesComponent,
  CustomerDetailOrdersComponent,
  CustomerDetailMailsComponent,
  CustomerDetailDiscountComponent,
  CustomerDetailTabsComponent,
  CustomerDetailWaliticketsComponent
} from '@components/customer/single'
import { HeaderTasksStatsComponent } from '@components/header-tasks-stats/header-tasks-stats.component'
import { WarrantyEditComponent, WarrantyNewComponent } from '@components/warranty/Single'
import { RabbitMqComponent } from '@components/rabbit/'
import { ReverseCaseComponent } from '@components/reverse'
import { ReverseCaseTasksComponent } from '@components/reverse'
import { ReverseCaseWorkshopComponent } from '@components/reverse'
import { ManufacturerEditComponent, ManufacturerNewComponent } from '@components/manufacturer/Single'
import { TaskTypeListComponent, TaskTypeEditComponent, TaskTypeUserListComponent } from "@components/task-manager/task-type"
import { UserSearchComponent } from "@components/generic/Form"
import { NotationListComponent } from '@components/notation/List'
import { SnackbarComponent, SnackbarService } from '@components/snackbar'
import { PageTitleComponent } from '@components/PageTitle'
import { GenericResultListComponent } from '@components/generic/List'
import { GenericPageComponent } from './components/generic/page/generic-page.component'
import { TabsComponent } from '@components/generic/tabs/tabs.component'
import { TaskManagerComponent } from '@components/generic/task-manager'
import { GridComponent } from '@components/generic/grid/grid.component'
import { HeaderComponent } from "@components/partials/header"
import { FormButtonsBarComponent } from '@components/generic/Form'
import { LoaderComponent, LoaderService } from '@components/loader'
import { CarrierEditComponent, CarrierCalculatorComponent, CarrierCapacityComponent, CarrierCapacityFormComponent, DockedVolumeComponent } from '@components/carrier'
import { TabListComponent } from '@components/tab/List'
import { ColorPickerComponent, EditableComponent } from '@components/generic/Form'
import { LoginComponent } from '@components/login/login.component'
import { OauthValidateComponent } from '@components/login/oauth-validate.component'
import { DashboardComponent } from '@components/dashboard/dashboard.component'
import { SalesComparisonComponent } from '@components/dashboard/sales-comparison/sales-comparison.component'
import { LanguageInterfaceComponent } from '@components/application/components/language-interface.component'
import { TableComponent } from '@components/generic/List/show-details/table'
import { NeedAttentionComponent } from '@components/order'
import { CarrierCostComponent } from '@components/carrier/cost/carrier-cost.component'
import { CarrierMeshAgencyComponent } from '@components/carrier/mesh-agency/carrier-mesh-agency.component'
import { CarrierBlacklistComponent } from '@components/carrier/blacklist/carrier-blacklist.component'
import { CarrierBlacklistPostalCodesComponent } from '@components/carrier/blacklist-postal-codes/carrier-blacklist-postal-codes.component'
import { OrderCollectionListComponent } from '@components/order-collection/list/order-collection-list.component'
import { SelectCountryComponent } from '@components/partials/select-country'
import { SelectLocaleComponent } from '@components/partials/select-locale'
import { SidebarComponent } from "@components/partials/sidebar"
import { UsersComponent } from '@components/users/users.component'
import { UsersListComponent } from '@components/users/list/users-list.component'
import { UsurpedComponent } from '@components/partials/usurped/usurped.component'
import { SlidingSidebarComponent } from './components/generic/sliding-sidebar'
import { ImportComponent } from "@components/generic/import-export/import.component"
import {
  AddressHelper,
  AttributeHelper,
  AttributeValueHelper,
  ColorHelper,
  CollectionHelper,
  OrderNewHelper,
  ProductHelper,
  SessionHelper,
  StringHelper,
  UrlHelper } from '@helpers'
import {
  AttributeFamilyResource,
  AttributeMarketplaceResource,
  AttributeResource,
  AttributeValueResource,
  CarrierResource,
  CarrierIconsResource,
  CarrierGroupResource,
  CategoryResource,
  BannerTypeRessource,
  CityResource,
  CMSResource,
  CommercialEntityResource,
  CountryResource,
  CustomerTypeResource,
  DiscountTranslationResource,
  MarketplaceResource,
  LogisticResource,
  InscpxeeResource,
  OrderItemResource,
  CrossSellingProductResource,
  ManufacturerResource,
  OrderResource,
  PackageResource,
  PaymentIconsResource,
  PictoResource,
  ScheduledTaskResource,
  ProductMarketplaceStatusResource,
  ReviewerResource,
  RoleHierarchyResource,
  OrderManagerStatusResource,
  StatusResource,
  UpSellingProductResource,
  UserResource,
  WebsiteConfigurationResource,
  WysiwygResource,
  CeStandardResource,
} from '@resources'
import { OrderItemStatusService } from '@components/order/services/order-item-status.service'
import { AuthService, CsvDataService, OrderDuplicateService } from '@services'
import { CategoriesMarketplaceResource } from '@components/categories-marketplace/categories-marketplace.resource'
import { ExportLogisticsComponent } from '@components/export-logistics/export-logistics.component'
import { ExportLogisticsHistoryComponent } from '@components/export-logistics/export-logistics-history/export-logistics-history.component'
import { ExportLogisticsScheduledComponent } from '@components/export-logistics/export-logistics-scheduled/export-logistics-scheduled.component'
import { ExportLogisticsCrossDockComponent } from '@components/export-logistics/export-logistics-cross-dock/export-logistics-cross-dock.component'
import { AttributeAttributeSetResource } from '@components/attribute-set/attribute-attribute-set.resource'
import { AttributeSetResource } from '@components/attribute-set/attribute-set.resource'
import { CatalogsResource } from '@components/catalogs/catalogs.resource'
import { CustomerResource } from './components/customer/customer.resource'
import { DiscountResource } from '@components/discount/resources/discount.resource'
import { DiscountCreditResource } from '@components/discount/resources/discount-credit.resource'
import { DiscountGiftResource } from '@components/discount/resources/discount-gift.resource'
import { DiscountProductResource } from '@components/discount/resources/discount-product.resource'
import { DiscountCartResource } from '@components/discount/resources/discount-cart.resource'
import { AutomaticDiscountResource } from '@components/automatic-discount/resources/automatic-discount.resource.ts'
import { DiscountIncludedCategoriesResource } from '@components/automatic-discount/resources/discount-included-categories.resource'
import { WarrantyResource } from '@components/warranty/resources/warranty.resource'
import { SuperProductResource } from './components/super-product/super-product.resource'
import { SuperProductCategoriesResource } from './components/super-product-categories/super-product-categories.resource'
import { ProductResource } from './components/product/product.resource'
import { PatternsResource } from './components/order/resources/patterns.resource'
import { ProductMarketplaceResource } from './components/product/product-marketplace.resource'
import { BreadcrumbComponent } from "@components/breadcrumb"
import { ExportComponent, ExportSelectionComponent } from "@components/export"
import { QualityExportComponent } from "@components/quality-export"
import { SalesLogExportComponent } from "@components/sales-log-export"
import { MarketplacesConfigureComponent, MarketplacesCommissionComponent, MarketplacesCommissionFormComponent } from "@components/marketplaces-configure"
import { TrackingSalesListComponent } from "@components/tracking-sales"
import { OrderManagerListComponent } from "@components/order-manager"
import { OrderManagerListLightComponent } from "@components/order-manager"
import { SparePartListComponent } from "@components/spare-part"
import { StockViewComponent } from "@components/stock"
import { ArrivalViewComponent } from "@components/stock"
import { StockRegularizationPackageListComponent } from "@components/stock"
import {
  StockShootingListComponent,
  StockShootingResultListComponent,
  StockShootingResultDetailComponent,
  StockShootingFormComponent,
} from "@components/stock-shooting"
import {
  StockMovementListComponent,
  StockMovementResultListComponent,
  StockMovementFormComponent,
  StockMovementResultDetailComponent
} from "@components/stock-movement"
import {
  SavOrderDetailComponent,
  SavSparePartSearchComponent,
  SavArrivalFormComponent,
  SavArrivalListComponent,
  SavOrdersListComponent,
  SavOrdersResultListComponent,
  SavOrderNewComponent
} from '@components/sav'
import { GrcProductListComponent, GrcProductResultListComponent, GrcProductDetailComponent, GrcDashboardComponent, GrcTicketsResultListComponent, GrcTicketsListComponent } from '@components/grc'
import { CommitmentListComponent, CommitmentResultListComponent } from '@components/commitment'
import { RoomFormComponent, RoomListComponent, RoomFormTranslationComponent, RoomBlockListComponent, RoomBlockResultListComponent } from '@components/room'
import {
  EstimateOrdersListComponent,
  EstimateOrdersResultListComponent,
} from '@components/estimate'

import { OrderManagerRelationComponent, OrderManagerRelationFormComponent } from '@components/order-manager/relation'
# @ngdoc overview
# @name ammsFrontendApp
# @description
# # ammsFrontendApp
#
# Main module of the application.
#
require('lodash')
window.Clipboard = require('clipboard')
require('angular-sanitize')
require('angular-ui-router')
require('angular-cookies')
require('angular-translate')
require('angular-translate-loader-static-files')
require('angular-translate-storage-cookie')
require('angular-translate-storage-local')
require('angular-dynamic-locale')
require('angular-storage')
require('angular-jwt')
require('angular-ui-bootstrap')
require('ui-select')
require('angular-xeditable')
require('angular-file-upload')
require('angular-drag-and-drop-lists')
require('angular-selectize2/dist/selectize')
require('iso-currency')
require('angular-drag-and-drop-lists')
require('angular-dragdrop')
require('bootstrap-switch')
require('angular-bootstrap-switch')
require('angularjs-slider') #check angular load more than once
require('angular-mocks')
require('floatthead')
require('angular-float-thead')

angular
  .module 'ammsFrontendApp', [
    'ngSanitize',
    'ui.router',
    'ngCookies',
    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'angular-storage',
    'angular-jwt',
    'ui.bootstrap',
    'ui.select',
    'xeditable',
    'angularFileUpload',
    'dndLists',
    'selectize',
    'isoCurrency',
    'ngDragDrop',
    'frapontillo.bootstrap-switch',
    'rzModule',
    'floatThead'
  ]
  .config(($compileProvider) ->
    $compileProvider.preAssignBindingsEnabled(true)
  )
  .directive('appBreadcrumb', downgradeComponent({ component: BreadcrumbComponent }))
  .directive('appLanguageInterface', downgradeComponent({ component: LanguageInterfaceComponent }))
  .directive('appAttributeList', downgradeComponent({ component: AttributeListComponent }))
  .directive('appAttributeEdit', downgradeComponent({ component: AttributeEditComponent }))
  .directive('appAttributeNew', downgradeComponent({ component: AttributeNewComponent }))
  .directive('appAttributeTable', downgradeComponent({ component: AttributeTableComponent }))
  .directive('appAttributeFamilyEdit', downgradeComponent({ component: AttributeFamilyEditComponent }))
  .directive('appAttributeSetList', downgradeComponent({ component: AttributeSetListComponent }))
  .directive('appAttributeSet', downgradeComponent({ component: AttributeSetComponent }))
  .directive('appBannerList', downgradeComponent({ component: BannerListComponent }))
  .directive('appBannerForm', downgradeComponent({ component: BannerFormComponent }))
  .directive('appBannerFormTranslation', downgradeComponent({ component: BannerFormTranslationComponent }))
  .directive('appButtonActionMultiple', downgradeComponent({ component: ButtonActionMultipleComponent }))
  .directive('appCarrierGroupList', downgradeComponent({ component: CarrierGroupListComponent }))
  .directive('appCarrierGroupListItem', downgradeComponent({ component: CarrierGroupListItemComponent }))
  .directive('appBreadcrumbs', downgradeComponent({ component: BreadcrumbsComponent }))
  .directive('appCarrierEdit', downgradeComponent({ component: CarrierEditComponent }))
  .directive('appCarrierCalculator', downgradeComponent({ component: CarrierCalculatorComponent }))
  .directive('appCarrierFamilyList', downgradeComponent({ component: CarrierFamilyListComponent }))
  .directive('appCarrierFamilyEdit', downgradeComponent({ component: CarrierFamilyEditComponent }))
  .directive('appCarrierFamilyNew', downgradeComponent({ component: CarrierFamilyNewComponent }))
  .directive('appCarrierFamilyShippingPrice', downgradeComponent({ component: CarrierFamilyShippingPriceComponent }))
  .directive('appCarrierFamilyResultList', downgradeComponent({ component: CarrierFamilyResultList }))
  .directive('appCarrierCost', downgradeComponent({ component: CarrierCostComponent }))
  .directive('appCarrierMeshAgency', downgradeComponent({ component: CarrierMeshAgencyComponent }))
  .directive('appCarrierBlacklist', downgradeComponent({ component: CarrierBlacklistComponent }))
  .directive('appCarrierBlacklistPostalCodes', downgradeComponent({ component: CarrierBlacklistPostalCodesComponent }))
  .directive('appCatalogsList', downgradeComponent({ component: CatalogsListComponent }))
  .directive('appCatalogs', downgradeComponent({ component: CatalogsComponent }))
  .directive('appCategoriesMarketplaceList', downgradeComponent({ component: CategoriesMarketplaceListComponent }))
  .directive('appCategories', downgradeComponent({ component: CategoriesComponent }))
  .directive('appCategoriesList', downgradeComponent({ component: CategoriesListComponent }))
  .directive('appChat', downgradeComponent({ component: ChatComponent }))
  .directive('appCmsList', downgradeComponent({ component: CMSListComponent }))
  .directive('appCmsForm', downgradeComponent({ component: CMSFormComponent }))
  .directive('appColorPicker', downgradeComponent({ component: ColorPickerComponent }))
  .directive('appCommercialOperationsList', downgradeComponent({ component: CommercialOperationsListComponent }))
  .directive('appCommercialOperations', downgradeComponent({ component: CommercialOperationsComponent }))
  .directive('appCommercialOperationsProducts', downgradeComponent({ component: CommercialOperationsProductsComponent }))
  .directive('appCommercialOperationsSeo', downgradeComponent({ component: CommercialOperationsSeoComponent }))
  .directive('appCommercialOperationsBlockList', downgradeComponent({ component: CommercialOperationsBlockListComponent }))
  .directive('appCommercialOperationsBlockResultList', downgradeComponent({ component: CommercialOperationsBlockResultListComponent }))
  .directive('appCustomerList', downgradeComponent({ component: CustomerListComponent }))
  .directive('appCustomerDetail', downgradeComponent({ component: CustomerDetailComponent }))
  .directive('appCustomerDetailAddresses', downgradeComponent({ component: CustomerDetailAddressesComponent }))
  .directive('appCustomerDetailOrders', downgradeComponent({ component: CustomerDetailOrdersComponent }))
  .directive('appCustomerDetailMails', downgradeComponent({ component: CustomerDetailMailsComponent }))
  .directive('appCustomerDetailDiscount', downgradeComponent({ component: CustomerDetailDiscountComponent }))
  .directive('appCustomerDetailTabs', downgradeComponent({ component: CustomerDetailTabsComponent }))
  .directive('appCustomerDetailWalitickets', downgradeComponent({ component: CustomerDetailWaliticketsComponent }))
  .directive('appDashboard', downgradeComponent({ component: DashboardComponent }))
  .directive('appAutomaticDiscountList', downgradeComponent({ component: AutomaticDiscountListComponent }))
  .directive('appAutomaticDiscountResultList', downgradeComponent({ component: AutomaticDiscountResultListComponent }))
  .directive('appAutomaticDiscountForm', downgradeComponent({ component: AutomaticDiscountFormComponent }))
  .directive('appDiscountList', downgradeComponent({ component: DiscountListComponent }))
  .directive('appDiscountResultList', downgradeComponent({ component: DiscountResultListComponent }))
  .directive('appDiscountForm', downgradeComponent({ component: DiscountFormComponent }))
  .directive('appSalesComparison', downgradeComponent({ component: SalesComparisonComponent }))
  .directive('appEditable', downgradeComponent({ component: EditableComponent }))
  .directive('appExportLogistics', downgradeComponent({ component: ExportLogisticsComponent }))
  .directive('appExportLogisticsHistory', downgradeComponent({ component: ExportLogisticsHistoryComponent }))
  .directive('appExportLogisticsScheduled', downgradeComponent({ component: ExportLogisticsScheduledComponent }))
  .directive('appExportLogisticsCrossDock', downgradeComponent({ component: ExportLogisticsCrossDockComponent }))
  .directive('appFormButtonsBar', downgradeComponent({ component: FormButtonsBarComponent }))
  .directive('appFormControl', downgradeComponent({ component: FormControlComponent }))
  .directive('appForm', downgradeComponent({ component: FormComponent }))
  .directive('appGenericPage', downgradeComponent({ component: GenericPageComponent }))
  .directive('appGenericResultList', downgradeComponent({ component: GenericResultListComponent }))
  .directive('appGrid', downgradeComponent({ component: GridComponent }))
  .directive('appHeader', downgradeComponent({ component: HeaderComponent }))
  .directive('appHeaderTasksStats', downgradeComponent({ component: HeaderTasksStatsComponent }))
  .directive('appLoader', downgradeComponent({ component: LoaderComponent }))
  .directive('appLogin', downgradeComponent({ component: LoginComponent }))
  .directive('appOauthValidate', downgradeComponent({ component: OauthValidateComponent }))
  .directive('appManufacturerList', downgradeComponent({ component: ManufacturerListComponent }))
  .directive('appManufacturerEdit', downgradeComponent({ component: ManufacturerEditComponent }))
  .directive('appManufacturerNew', downgradeComponent({ component: ManufacturerNewComponent }))
  .directive('appTaskTypeList', downgradeComponent({ component: TaskTypeListComponent }))
  .directive('appTaskTypeEdit', downgradeComponent({ component: TaskTypeEditComponent }))
  .directive('appTaskTypeUserList', downgradeComponent({ component: TaskTypeUserListComponent }))
  .directive('appUserSearch', downgradeComponent({ component: UserSearchComponent }))
  .directive('appNeedAttention', downgradeComponent({ component: NeedAttentionComponent }))
  .directive('appNotationList', downgradeComponent({ component: NotationListComponent }))
  .directive('appOrderResultList', downgradeComponent({ component: OrderResultListComponent }))
  .directive('appOrderResultListTr', downgradeComponent({ component: OrderResultListTrComponent }))
  .directive('appOrderCollectionList', downgradeComponent({ component: OrderCollectionListComponent }))
  .directive('appOrderRefund', downgradeComponent({ component: OrderRefundComponent }))
  .directive('appOrderCreation', downgradeComponent({ component: OrderCreationComponent }))
  .directive('appOrderManagerForm', downgradeComponent({ component: OrderManagerFormComponent }))
  .directive('appOrderManagerScalingForm', downgradeComponent({ component: OrderManagerScalingFormComponent }))
  .directive('appOrderManagerScalingList', downgradeComponent({ component: OrderManagerScalingListComponent }))
  .directive('appOrderManagerSageExportList', downgradeComponent({ component: OrderManagerSageExportListComponent }))
  .directive('appOrderManagerSageExportResultList', downgradeComponent({ component: OrderManagerSageExportResultListComponent }))
  .directive('appDelayReasonOrderList', downgradeComponent({ component: DelayReasonOrderListComponent }))
  .directive('appDelayReasonOrderResultList', downgradeComponent({ component: DelayReasonOrderResultListComponent }))
  .directive('appPageTitle', downgradeComponent({ component: PageTitleComponent }))
  .directive('appPaymentCarrierIcons', downgradeComponent({ component: PaymentCarrierIconsComponent }))
  .directive('appImport', downgradeComponent({ component: ImportComponent }))
  .directive('appProductPage', downgradeComponent({ component: ProductPageComponent }))
  .directive('appProductLightPage', downgradeComponent({ component: ProductLightPageComponent }))
  .directive('appSavOrderDetail', downgradeComponent({ component: SavOrderDetailComponent }))
  .directive('appSavSparePartSearch', downgradeComponent({ component: SavSparePartSearchComponent }))
  .directive('appSavArrivalForm', downgradeComponent({ component: SavArrivalFormComponent }))
  .directive('appSavArrivalList', downgradeComponent({ component: SavArrivalListComponent }))
  .directive('appSavOrdersList', downgradeComponent({ component: SavOrdersListComponent }))
  .directive('appEstimateOrdersList', downgradeComponent({ component: EstimateOrdersListComponent }))
  .directive('appSavOrdersResultList', downgradeComponent({ component: SavOrdersResultListComponent }))
  .directive('appEstimateOrdersResultList', downgradeComponent({ component: EstimateOrdersResultListComponent }))
  .directive('appSavOrderNew', downgradeComponent({ component: SavOrderNewComponent }))
  .directive('appSparePartPage', downgradeComponent({ component: SparePartPageComponent }))
  .directive('appSparePartPageWarehouseAgent', downgradeComponent({ component: SparePartPageWarehouseAgentComponent }))
  .directive('appSparePartSearch', downgradeComponent({ component: SparePartSearchComponent }))
  .directive('appProductStatus', downgradeComponent({ component: ProductStatusComponent }))
  .directive('appProductStatusEditable', downgradeComponent({ component: ProductStatusEditableComponent }))
  .directive('appProductCarrierForm', downgradeComponent({ component: ProductCarrierFormComponent }))
  .directive('appProductLogisticForm', downgradeComponent({ component: ProductLogisticFormComponent }))
  .directive('appProductGeneralForm', downgradeComponent({ component: ProductGeneralFormComponent }))
  .directive('appSparePartProductGeneralForm', downgradeComponent({ component: SparePartProductGeneralFormComponent }))
  .directive('appProductContentTab', downgradeComponent({ component: ProductContentTabComponent }))
  .directive('appProductContentForm', downgradeComponent({ component: ProductContentFormComponent }))
  .directive('appProductAttributesForm', downgradeComponent({ component: ProductAttributesFormComponent }))
  .directive('appProductContentFormTranslations', downgradeComponent({ component: ProductContentFormTranslationsComponent }))
  .directive('appProductPicturesForm', downgradeComponent({ component: ProductPicturesFormComponent }))
  .directive('appProductSageForm', downgradeComponent({ component: ProductSageFormComponent }))
  .directive('appProductVersionsForm', downgradeComponent({ component: ProductVersionsFormComponent }))
  .directive('appProductMarketplaceForms', downgradeComponent({ component: ProductMarketplaceFormsComponent }))
  .directive('appProductMarketplaceGeneralForm', downgradeComponent({ component: ProductMarketplaceGeneralFormComponent }))
  .directive('appProductWebsiteMarketplaceForm', downgradeComponent({ component: ProductWebsiteMarketplaceFormComponent }))
  .directive('appDuplicateProduct', downgradeComponent({ component: DuplicateProductComponent }))
  .directive('appImportProduct', downgradeComponent({ component: ImportProductComponent }))
  .directive('appImportProductFields', downgradeComponent({ component: ImportProductFieldsComponent }))
  .directive('appProductList', downgradeComponent({ component: ProductListComponent }))
  .directive('appExport', downgradeComponent({ component: ExportComponent }))
  .directive('appExportSelection', downgradeComponent({ component: ExportSelectionComponent }))
  .directive('appQualityExport', downgradeComponent({ component: QualityExportComponent }))
  .directive('appSalesLogExport', downgradeComponent({ component: SalesLogExportComponent }))
  .directive('appMarketplacesCommissionForm', downgradeComponent({ component: MarketplacesCommissionFormComponent }))
  .directive('appMarketplacesCommission', downgradeComponent({ component: MarketplacesCommissionComponent }))
  .directive('appMarketplacesConfigure', downgradeComponent({ component: MarketplacesConfigureComponent }))
  .directive('appPurchaseServiceForm', downgradeComponent({ component: PurchaseServiceFormComponent }))
  .directive('appRabbitMq', downgradeComponent({ component: RabbitMqComponent }))
  .directive('appRedirectList', downgradeComponent({ component: RedirectListComponent }))
  .directive('appRedirects', downgradeComponent({ component: RedirectsComponent }))
  .directive('appRefundsList', downgradeComponent({ component: RefundsListComponent }))
  .directive('appFooterLinksList', downgradeComponent({ component: FooterLinksListComponent }))
  .directive('appFooterLinksResultList', downgradeComponent({ component: FooterLinksResultListComponent }))
  .directive('appReinsurancePointsList', downgradeComponent({ component: ReinsurancePointsListComponent }))
  .directive('appReinsurancePointsResultList', downgradeComponent({ component: ReinsurancePointsResultListComponent }))
  .directive('appReinsurancePoints', downgradeComponent({ component: ReinsurancePointsComponent }))
  .directive('appReverseList', downgradeComponent({ component: ReverseListComponent }))
  .directive('appReverseCase', downgradeComponent({ component: ReverseCaseComponent }))
  .directive('appReverseCaseTasks', downgradeComponent({ component: ReverseCaseTasksComponent }))
  .directive('appReverseCaseWorkshop', downgradeComponent({ component: ReverseCaseWorkshopComponent }))
  .directive('appRoomList', downgradeComponent({ component: RoomListComponent }))
  .directive('appRoomForm', downgradeComponent({ component: RoomFormComponent }))
  .directive('appRoomFormTranslation', downgradeComponent({ component: RoomFormTranslationComponent }))
  .directive('appRoomBlockList', downgradeComponent({ component: RoomBlockListComponent }))
  .directive('appRoomBlockResultList', downgradeComponent({ component: RoomBlockResultListComponent }))
  .directive('appSelectCountry', downgradeComponent({ component: SelectCountryComponent }))
  .directive('appSelectLocale', downgradeComponent({ component: SelectLocaleComponent }))
  .directive('appSeoForm', downgradeComponent({ component: SeoFormComponent }))
  .directive('appCategoriesSatelliteList', downgradeComponent({ component: CategoriesSatelliteListComponent }))
  .directive('appCategoriesSatelliteResultList', downgradeComponent({ component: CategoriesSatelliteResultListComponent }))
  .directive('appCategoriesSatelliteForm', downgradeComponent({ component: CategoriesSatelliteFormComponent }))
  .directive('appCategoryBlocksForm', downgradeComponent({ component: CategoryBlocksFormComponent }))
  .directive('appSidebar', downgradeComponent({ component: SidebarComponent }))
  .directive('appSlidingSidebar', downgradeComponent({ component: SlidingSidebarComponent }))
  .directive('appServiceForm', downgradeComponent({ component: ServiceFormComponent }))
  .directive('appShippingPriceForm', downgradeComponent({ component: ShippingPriceFormComponent }))
  .directive('appShippingPriceList', downgradeComponent({ component: ShippingPriceListComponent }))
  .directive('appServiceResultList', downgradeComponent({ component: ServiceResultListComponent }))
  .directive('appServiceList', downgradeComponent({ component: ServiceListComponent }))
  .directive('appStockImportList', downgradeComponent({ component: StockImportListComponent }))
  .directive('appSuperProductsForm', downgradeComponent({ component: SuperProductsFormComponent }))
  .directive('appSuperProductList', downgradeComponent({ component: SuperProductListComponent }))
  .directive('appImportSuperProduct', downgradeComponent({ component: ImportSuperProductComponent }))
  .directive('appSuperProduct', downgradeComponent({ component: SuperProductComponent }))
  .directive('appSuperProductContentWebsite', downgradeComponent({ component: SuperProductContentWebsiteComponent }))
  .directive('appStockView', downgradeComponent({ component: StockViewComponent }))
  .directive('appArrivalView', downgradeComponent({ component: ArrivalViewComponent }))
  .directive('appStockRegularizationPackageList', downgradeComponent({ component: StockRegularizationPackageListComponent }))
  .directive('appStockMovementList', downgradeComponent({ component: StockMovementListComponent }))
  .directive('appStockMovementResultList', downgradeComponent({ component: StockMovementResultListComponent }))
  .directive('appStockMovementForm', downgradeComponent({ component: StockMovementFormComponent }))
  .directive('appStockMovementResultDetail', downgradeComponent({ component: StockMovementResultDetailComponent }))
  .directive('appStockShootingList', downgradeComponent({ component: StockShootingListComponent }))
  .directive('appStockShootingResultList', downgradeComponent({ component: StockShootingResultListComponent }))
  .directive('appStockShootingResultDetail', downgradeComponent({ component: StockShootingResultDetailComponent }))
  .directive('appStockShootingForm', downgradeComponent({ component: StockShootingFormComponent }))
  .directive('appCustomerPhotos', downgradeComponent({ component: CustomerPhotosComponent }))
  .directive('appUserGuide', downgradeComponent({ component: UserGuideComponent }))
  .directive('appSnackbarGroup', downgradeComponent({ component: SnackbarComponent }))
  .directive('appTabLinkedProductsForm', downgradeComponent({ component: TabLinkedProductsComponent }))
  .directive('appProductContentWebsite', downgradeComponent({ component: ProductContentWebsiteComponent }))
  .directive('appTrackingSalesList', downgradeComponent({ component: TrackingSalesListComponent }))
  .directive('appSparePartList', downgradeComponent({ component: SparePartListComponent }))
  .directive('appOrderManagerList', downgradeComponent({ component: OrderManagerListComponent }))
  .directive('appOrderManagerListLight', downgradeComponent({ component: OrderManagerListLightComponent }))
  .directive('appOrderManagerRelation', downgradeComponent({ component: OrderManagerRelationComponent }))
  .directive('appOrderManagerRelationForm', downgradeComponent({ component: OrderManagerRelationFormComponent }))
  .directive('appTabs', downgradeComponent({ component: TabsComponent }))
  .directive('appTabList', downgradeComponent({ component: TabListComponent }))
  .directive('appTab', downgradeComponent({ component: TabComponent }))
  .directive('appTaskManager', downgradeComponent({ component: TaskManagerComponent }))
  .directive('appUsers', downgradeComponent({ component: UsersComponent }))
  .directive('appUsersList', downgradeComponent({ component: UsersListComponent }))
  .directive('appUsurped', downgradeComponent({ component: UsurpedComponent }))
  .directive('appCustomerPhotos', downgradeComponent({ component: CustomerPhotosComponent }))
  .directive('appUserGuide', downgradeComponent({ component: UserGuideComponent }))
  .directive('appWarrantyList', downgradeComponent({ component: WarrantyListComponent }))
  .directive('appWarrantyEdit', downgradeComponent({ component: WarrantyEditComponent }))
  .directive('appWarehouseList', downgradeComponent({ component: WarehouseListComponent }))
  .directive('appWarehousesEdit', downgradeComponent({ component: WarehousesEditComponent }))
  .directive('appWarrantyNew', downgradeComponent({ component: WarrantyNewComponent }))
  .directive('appWebsiteCategoryList', downgradeComponent({ component: WebsiteCategoryListComponent }))
  .directive('appWebsiteCategoryResultList', downgradeComponent({ component: WebsiteCategoryResultListComponent }))
  .directive('appWebsiteCategoryForm', downgradeComponent({ component: WebsiteCategoryFormComponent }))
  .directive('appWebsiteModule', downgradeComponent({ component: WebsiteModuleComponent }))
  .directive('appWebsiteHomepageAdvices', downgradeComponent({ component: WebsiteHomepageAdvicesComponent }))
  .directive('appWebsiteHomepageSeo', downgradeComponent({ component: WebsiteHomepageSeoComponent }))
  .directive('appWebsiteHomepageHighlights', downgradeComponent({ component: WebsiteHomepageHighlightsComponent }))
  .directive('appWebsiteHomepageBargains', downgradeComponent({ component: WebsiteHomepageBargainsComponent }))
  .directive('appWebsiteHomepageSlider', downgradeComponent({ component: WebsiteHomepageSliderComponent }))
  .directive('appWebsiteHomepageSliderItems', downgradeComponent({ component: WebsiteHomepageSliderItemsComponent }))
  .directive('appWebsiteHomepageSliderItemsList', downgradeComponent({ component: WebsiteHomepageSliderItemsListComponent }))
  .directive('appWebsiteHomepageSliderItemsResultList', downgradeComponent({ component: WebsiteHomepageSliderItemsResultListComponent }))
  .directive('appWebsiteHomepageHighlightsItems', downgradeComponent({ component: WebsiteHomepageHighlightsItemsComponent }))
  .directive('appWebsiteHomepageHighlightsItemsList', downgradeComponent({ component: WebsiteHomepageHighlightsItemsListComponent }))
  .directive('appWebsiteHomepageHighlightsItemsResultList', downgradeComponent({ component: WebsiteHomepageHighlightsItemsResultListComponent }))
  .directive('appLogistic', downgradeComponent({ component: LogisticComponent }))
  .directive('appTable', downgradeComponent({ component: TableComponent }))
  .directive('appGrcProductList', downgradeComponent({ component: GrcProductListComponent }))
  .directive('appGrcProductResultList', downgradeComponent({ component: GrcProductResultListComponent }))
  .directive('appCommitmentList', downgradeComponent({ component: CommitmentListComponent }))
  .directive('appCommitmentResultList', downgradeComponent({ component: CommitmentResultListComponent }))
  .directive('appGrcProductDetail', downgradeComponent({ component: GrcProductDetailComponent }))
  .directive('appGrcDashboard', downgradeComponent({ component: GrcDashboardComponent }))
  .directive('appGrcTickets', downgradeComponent({ component: GrcTicketsResultListComponent }))
  .directive('appGrcTicketsList', downgradeComponent({ component: GrcTicketsListComponent }))
  .directive('appUnpairedList', downgradeComponent({ component: UnpairedListComponent }))
  .directive('appCarrierCapacity', downgradeComponent({ component: CarrierCapacityComponent }))
  .directive('appCarrierCapacityForm', downgradeComponent({ component: CarrierCapacityFormComponent }))
  .directive('appDockedVolume', downgradeComponent({ component: DockedVolumeComponent }))
  .directive('appAddressingList', downgradeComponent({ component: AddressingListComponent }))
  .directive('appLitigationList',  downgradeComponent({ component: LitigationListComponent }))
  .directive('appLitigationResultList',  downgradeComponent({ component: LitigationResultListComponent }))
  .directive('appLogisticWaveList',  downgradeComponent({ component: LogisticWaveListComponent }))
  .directive('appLogisticWaveResultList',  downgradeComponent({ component: LogisticWaveResultListComponent }))
  .directive('appOneyOpcList',  downgradeComponent({ component: OneyOpcListComponent }))
  .directive('appOneyOpcForm',  downgradeComponent({ component: OneyOpcFormComponent }))
  .directive('appEavAttributeForm', downgradeComponent({ component: EavAttributeFormComponent }))
  .directive('appEavAttributeList', downgradeComponent({ component: EavAttributeListComponent }))
  .directive('appGlossaryForm', downgradeComponent({ component: GlossaryFormComponent }))
  .directive('appGlossaryList', downgradeComponent({ component: GlossaryListComponent }))
  .directive('appSupplierList', downgradeComponent({ component: SupplierListComponent }))
  .directive('appSupplierForm', downgradeComponent({ component: SupplierFormComponent }))
  .directive('appProformaInvoiceList', downgradeComponent({ component: ProformaInvoiceListComponent }))
  .directive('appProformaInvoiceForm', downgradeComponent({ component: ProformaInvoiceFormComponent }))
  .directive('appProformaInvoiceImport', downgradeComponent({ component: ProformaInvoiceImportComponent }))
  .directive('appContainerForm', downgradeComponent({ component: ContainerFormComponent }))
  .directive('appContainerList', downgradeComponent({ component: ContainerListComponent }))
  .service('AddressHelper', AddressHelper)
  .service('AttributeHelper', AttributeHelper)
  .service('AttributeValueHelper', AttributeValueHelper)
  .service('CollectionHelper', CollectionHelper)
  .service('OrderNewHelper', OrderNewHelper)
  .service('ProductHelper', ProductHelper)
  .service('SessionHelper', downgradeInjectable(SessionHelper))
  .service('StringHelper', StringHelper)
  .service('AttributeAttributeSetResource', downgradeInjectable(AttributeAttributeSetResource))
  .service('AttributeSetResource', downgradeInjectable(AttributeSetResource))
  .service('AttributeFamilyResource', downgradeInjectable(AttributeFamilyResource))
  .service('AttributeMarketplaceResource', downgradeInjectable(AttributeMarketplaceResource))
  .service('AttributeResource', downgradeInjectable(AttributeResource))
  .service('AttributeValueResource', downgradeInjectable(AttributeValueResource))
  .service('AuthService', downgradeInjectable(AuthService))
  .service('OrderDuplicateService', downgradeInjectable(OrderDuplicateService))
  .service('CarrierFamilyResource', downgradeInjectable(CarrierFamilyResource))
  .service('CarrierResource', downgradeInjectable(CarrierResource))
  .service('CarrierIconsResource', downgradeInjectable(CarrierIconsResource))
  .service('CarrierGroupResource', downgradeInjectable(CarrierGroupResource))
  .service('CatalogsResource', downgradeInjectable(CatalogsResource))
  .service('CategoriesMarketplaceResource', downgradeInjectable(CategoriesMarketplaceResource))
  .service('CategoryResource', downgradeInjectable(CategoryResource))
  .service('BannerTypeRessource', downgradeInjectable(BannerTypeRessource))
  .service('CityResource', downgradeInjectable(CityResource))
  .service('CMSResource', downgradeInjectable(CMSResource))
  .service('CommercialEntityResource', downgradeInjectable(CommercialEntityResource))
  .service('CountryResource', downgradeInjectable(CountryResource))
  .service('CustomerTypeResource', downgradeInjectable(CustomerTypeResource))
  .service('CustomerResource', downgradeInjectable(CustomerResource))
  .service('LogisticResource', downgradeInjectable(LogisticResource))
  .service('InscpxeeResource', downgradeInjectable(InscpxeeResource))
  .service('DiscountResource', downgradeInjectable(DiscountResource))
  .service('DiscountCreditResource', downgradeInjectable(DiscountCreditResource))
  .service('DiscountGiftResource', downgradeInjectable(DiscountGiftResource))
  .service('DiscountProductResource', downgradeInjectable(DiscountProductResource))
  .service('DiscountCartResource', downgradeInjectable(DiscountCartResource))
  .service('AutomaticDiscountResource', downgradeInjectable(AutomaticDiscountResource))
  .service('DiscountIncludedCategoriesResource', downgradeInjectable(DiscountIncludedCategoriesResource))
  .service('DiscountTranslationResource', downgradeInjectable(DiscountTranslationResource))
  .service('ColorHelper', downgradeInjectable(ColorHelper))
  .service('CrossSellingProductResource', downgradeInjectable(CrossSellingProductResource))
  .service('LoaderService', downgradeInjectable(LoaderService))
  .service('ManufacturerResource', downgradeInjectable(ManufacturerResource))
  .service('OrderItemResource', downgradeInjectable(OrderItemResource))
  .service('OrderItemStatusService', downgradeInjectable(OrderItemStatusService))
  .service('OrderResource', downgradeInjectable(OrderResource))
  .service('MarketplaceResource', downgradeInjectable(MarketplaceResource))
  .service('PackageResource', downgradeInjectable(PackageResource))
  .service('PaymentIconsResource', downgradeInjectable(PaymentIconsResource))
  .service('PictoResource', downgradeInjectable(PictoResource))
  .service('ScheduledTaskResource', downgradeInjectable(ScheduledTaskResource))
  .service('ProductResource', downgradeInjectable(ProductResource))
  .service('ProductMarketplaceResource', downgradeInjectable(ProductMarketplaceResource))
  .service('ProductMarketplaceStatusResource', downgradeInjectable(ProductMarketplaceStatusResource))
  .service('ReviewerResource', downgradeInjectable(ReviewerResource))
  .service('RoleHierarchyResource', downgradeInjectable(RoleHierarchyResource))
  .service('StatusResource', downgradeInjectable(StatusResource))
  .service('OrderManagerStatusResource', downgradeInjectable(OrderManagerStatusResource))
  .service('Snackbar', downgradeInjectable(SnackbarService))
  .service('SuperProductResource', downgradeInjectable(SuperProductResource))
  .service('SuperProductCategoriesResource', downgradeInjectable(SuperProductCategoriesResource))
  .service('UpSellingProductResource', downgradeInjectable(UpSellingProductResource))
  .service('UserResource', downgradeInjectable(UserResource))
  .service('UrlHelper', downgradeInjectable(UrlHelper))
  .service('WarrantyResource', downgradeInjectable(WarrantyResource))
  .service('WebsiteConfigurationResource', downgradeInjectable(WebsiteConfigurationResource))
  .service('WysiwygResource', downgradeInjectable(WysiwygResource))
  .service('CsvDataService', downgradeInjectable(CsvDataService))
  .service('PatternsResource', downgradeInjectable(PatternsResource))
  .service('CeStandardResource', downgradeInjectable(CeStandardResource))

require('../styles/main.scss')

require('@config/http')
require('@config/router')
require('@config/translate')
require('@config/ui-bootstrap')
require('@config/ui-select')
require('@config/xeditable')

require('@factories/http-interceptor')
require('@factories/interface-language')
require('@factories/list-filter')
require('@factories/list-order-session')
require('@factories/menu')
require('@factories/order-checker')
require('@factories/product-checker')

require('@helpers/AddressHelper')
require('@helpers/ColorHelper')
require('@helpers/CollectionHelper')
require('@helpers/ImageTypeHelper')
require('@helpers/ModelHelper')
require('@helpers/NumberHelper')
require('@helpers/OrderHelper')

require('@listeners/security')

require('@services/CountryService')
require('@services/DialogService')

require('@filters/carrier-name')
require('@filters/carrier')
require('@filters/count-char')
require('@filters/html-safe')
require('@filters/marketplace-commercial-name')
require('@filters/marketplace-name')
require('@filters/marketplace-parent-code')
require('@filters/nl2br')
require('@filters/stock-by-warehouse')

require('@directives/Ckeditor')
require('@directives/cmup-tooltip')
require('@directives/CopyButton')
require('@directives/dgs-processing')
require('@directives/daterangepicker')
require('@directives/draggable-row')
require('@directives/filter-pagination')
require('@directives/filter-select')
require('@directives/flag')
require('@directives/form-error-message')
require('@directives/form-select')
require('@directives/FormHasErrors')
require('@directives/FrozenTableHeader')
require('@directives/ignore-mouse-wheel')
require('@directives/label-marketplace')
require('@directives/LangSwitchingWarning')
require('@directives/Price')
require('@directives/table-sort')
require('@directives/user-accordion')

require('@controllers/default/form/FormDisplayErrorsCmp')
require('@controllers/default/form/FormDisplayGlobalErrorsCmp')
require('@controllers/default/CategorySelectorCmp')
require('@controllers/default/FormSelectorCmp')
require('@controllers/default/PictoSelectorCmp')
require('@controllers/default/SwitchCmp')
require('@controllers/orders/modals/change-alert')
require('@controllers/orders/new')
require('@controllers/products/list/BulkEditorCmp')
require('@controllers/products/list/FiltersCmp')
require('@controllers/products/list/ListCmp')
require('@controllers/products/list/Pagination')
require('@controllers/products/list/RowECommerceCmp')
require('@controllers/products/list/TabECommerceCmp')

require('./app.module')
