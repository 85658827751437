import { NgModule } from '@angular/core';
import { ChatComponent } from '@components/chat/chat.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ChatResource } from '@components/chat/chat.resource';
import { MarkdownToHtmlModule } from 'ng2-markdown-to-html';

@NgModule({
  declarations: [
    ChatComponent
  ],
  entryComponents: [
    ChatComponent,
  ],
  exports: [
    ChatComponent
  ],
  providers: [
    ChatResource
  ],
  imports: [
      CommonModule,
      FormsModule,
      MarkdownToHtmlModule
  ]
})

export class ChatModule {}
