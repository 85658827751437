import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'chat',
  entryPoint: '/v2/chat/send-message',
  translationKey: 'CHAT',
  isHydra: false,
})
export class ChatResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
