import {Component, ComponentFactoryResolver, Inject, OnInit} from '@angular/core';
import { GenericResultListComponent } from '@components/generic/List';
import { FormService } from '@services/form.service';
import {AbstractComponent} from '@components/generic/abstract.component';
import {AbstractPageComponent} from '@components/generic/abstract-page.component';
import {AuthService} from '@services';
import {AbstractResource, StockMovementLineResource} from '@resources';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SnackbarService} from '@components/snackbar';
import {WarehousesResource} from '@components/warehouses';
import {UrlHelper} from '@helpers';
import {IWarehouses} from '@components/warehouses/models';
import {HydraHelper} from '@helpers/HydraHelper';
import {takeUntil} from 'rxjs/operators';
import {StockMovementLineModel} from '@components/stock-movement/stock-movement-line.model';

@Component({
  selector: 'app-stock-movement-result-detail',
  template: require('./stock-movement-result-detail.component.html'),
  providers: [
    FormService,
    {
      provide: AbstractResource, useClass: StockMovementLineResource
    }
  ],
})

export class StockMovementResultDetailComponent extends GenericResultListComponent implements OnInit {
  public toWarehouses: any[] = [];
  public transferForm: FormGroup = new FormGroup({
    toWarehouse: new FormControl(null),
  });
  public stockMovementLinesSummary: StockMovementLineModel[] = [];
  public allItemsSelected: boolean = false;
  public openModal: boolean = false;
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('DialogService') dialog: any,
    urlHelper: UrlHelper,
    componentFactoryResolver: ComponentFactoryResolver,
    private snackbar: SnackbarService,
    private warehouseResource: WarehousesResource,
  ) {
    super($translate, state, authService, resource, dialog, urlHelper, componentFactoryResolver);
  }

  public ngOnInit() {
    this.resource.cGet({ 'stockMovement.id': this.state.params.id, pagination: false }, {
      useModel: true,
      model: StockMovementLineModel,
      isHydra: true,
      returnHydraMembers: true,
    })
      .takeUntil(this.destroyed$)
      .subscribe((response: StockMovementLineModel[]): void => {
        this.stockMovementLinesSummary = response;
      });
  }

  public toggleSelectAll() {
    this.stockMovementLinesSummary.forEach(line => {
      line.checked = this.allItemsSelected;
    });
  }

  public fetchWarehouse(): void {
    this.warehouseResource.getMany(
      { pagination: false },
      { dontUseModel: true }
    )
      .takeUntil(this.destroyed$)
      .subscribe((warehouses: IWarehouses[]) => {
        warehouses.forEach((warehouse: IWarehouses) => {
          if (
            ('repatriation' === this.stockMovementLinesSummary[0].orderType && warehouse.name === this.stockMovementLinesSummary[0].to)
            || (
              'shooting' === this.stockMovementLinesSummary[0].orderType
              && (warehouse.code === 'ecospace' || warehouse.code === 'alice_deals' || warehouse.code === 'lsl')
            )
          ) {
            this.toWarehouses.push(warehouse);
          }
        });
      })
    ;
  }

  public openTransferModal(): void {
    this.fetchWarehouse();
    this.openModal = true;
  }

  public closeTransferModal(): void {
    this.openModal = false;
    this.transferForm.get('toWarehouse').setValue(null);
  }

  public getSelectedMovementLines(): string[] {
    const movementLinesIds: string[] = [];

    this.stockMovementLinesSummary.forEach((line: any) => {
      if (line.checked) {
        movementLinesIds.push(HydraHelper.buildIri(line.id, 'stock_movement_lines'));
      }
    });

    return movementLinesIds;
  }

  public receiveLines() {
    this.dialog.confirm(this.translate('PAGE.STOCK_SHOOTING.CONFIRM.RECEIVE'))
      .then(() => {
        this.resource.bulkReceiveMovementLines(this.getSelectedMovementLines())
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: any) => {
            this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
            this.state.go(this.state.current, this.state.params, {reload: true});
          })
        ;
      })
    ;
  }

  public transferLines() {
    this.resource.bulkTransferMovementLines(this.getSelectedMovementLines(), this.transferForm.get('toWarehouse').value)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
        this.state.go(this.state.current, this.state.params, {reload: true});
      })
    ;
  }
}
