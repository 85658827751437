import {
  ICustomer, IDiscountCommonList,
  IDiscountFormGeneralModel, IDiscountRejectionTranslations,
  IDiscountType,
  IProduct
} from '@components/discount/interfaces/discount-form.interface';
import * as moment from 'moment';
import { FULL_EUROPEEN_DATE_FORMAT } from '@constants/date';

export class DiscountFormGeneralModel implements IDiscountFormGeneralModel {
  id: string;
  country: any;
  name: string;
  description: string;
  dateBegin: string;
  dateEnd: string;
  active: boolean;
  type: IDiscountType;
  combinable: boolean;
  customers: ICustomer[];
  excludeSales: boolean;
  includedProducts: IProduct[];
  excludedProducts: (IDiscountCommonList|string)[];
  includedCategories: (IDiscountCommonList|string)[];
  excludedCategories: (IDiscountCommonList|string)[];
  minimumAmount: number;
  maximumAmount: number;
  maximumUsage: number;
  maximumUsagePerCustomer: number;
  zendeskTicketId: string;
  readOnly?: boolean;
  translations?: IDiscountRejectionTranslations;
  highlight?: boolean;

  constructor(model: IDiscountFormGeneralModel) {
    this.id = model.id;
    this.name = model.name;
    this.description = model.description ? model.description : null;
    this.dateBegin = model.dateBegin;
    this.dateEnd = model.dateEnd;
    this.active = model.active;
    this.type = model.type;
    this.combinable = model.combinable;
    this.customers = model.customers ? model.customers.map((customer: ICustomer) => {
      return { id: customer.id, username: customer.username };
    }) : null;
    this.excludeSales = model.excludeSales;
    // this.includedProducts = model.includedProducts.map((includedProduct: {id: string}) => includedProduct.id);
    this.includedProducts = model.includedProducts ? model.includedProducts.map((includedProduct: IProduct) => {
      return { id: includedProduct.id, sku: includedProduct.sku };
    }) : null;
    this.excludedProducts = model.excludedProducts.map((excludedProduct: {id: string}) => excludedProduct.id);
    this.includedCategories = model.includedCategories.map((includedCategories: any) => includedCategories.category.id);
    this.excludedCategories = model.excludedCategories.map((excludedCategories: {id: string}) => excludedCategories.id);
    this.minimumAmount = model.minimumAmount ? model.minimumAmount : null;
    this.maximumAmount = model.maximumAmount ? model.maximumAmount : null;
    this.maximumUsage = model.maximumUsage ? model.maximumUsage : null;
    this.maximumUsagePerCustomer = model.maximumUsagePerCustomer ? model.maximumUsagePerCustomer : null;
    this.zendeskTicketId = model.zendeskTicketId ? model.zendeskTicketId : null;
    this.readOnly = model.readOnly;
    this.translations = model.translations;
    this.highlight = model.highlight;
  }
}
