import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import {AbstractResource, OrderResource, StockMovementResource} from '@resources';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { SessionHelper } from '@helpers';
import { SnackbarService } from '@components/snackbar';
import { IWarehouses } from '@components/warehouses/models';
import { StockMovementLineResource } from '@resources/stock-movement-line.resource';
import {takeUntil} from 'rxjs/operators';
import {WarehousesResource} from '@components/warehouses';
import {HydraHelper} from '@helpers/HydraHelper';
import {IFileInfo} from '@components/generic/Form/file-uploader/interfaces/file-info.interface';
import {ImportResource} from '@components/generic/import-export/import.resource';

@Component({
  selector: 'app-stock-movement-form',
  template: require('./stock-movement-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: StockMovementLineResource },
    ImportResource
  ],
})
export class StockMovementFormComponent extends AbstractPageComponent implements OnInit {
  public form: FormGroup;
  public warehouses: any[] = SessionHelper.getAllWarehouses();
  public fromWarehouse: any[] = this.warehouses;
  public toWarehouse: any[] = this.warehouses;

  public countryCode: string = SessionHelper.getCountry().code;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private warehouseResource: WarehousesResource,
    private importResource: ImportResource,
    private stockMovementResource: StockMovementResource
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.buildForm();
    this.initDefaultValues();
  }

  public buildForm(): void {
    this.form = this.formBuilder.group({
      type: [{'@id': 'ecospace'}, Validators.required],
      fromWarehouse: [null],
      toWarehouse: [null],
      file: [null, Validators.required],
    });
  }

  public initDefaultValues(): void {
    this.form.get('type').patchValue('ecospace');

    if (this.form.get('type').value === 'ecospace') {
      this.fromWarehouse = this.warehouses.filter((warehouse: IWarehouses): boolean => {
        return warehouse.code === 'lsl';
      });
      this.fromWarehouse.push({'@id': '', 'name': null});
    }

    this.form.get('fromWarehouse').setValue(this.warehouses.find((warehouse: IWarehouses): boolean => {
      return warehouse.code === 'lsl';
    })['@id']);

    this.form.get('toWarehouse').setValue(this.warehouses.find((warehouse: IWarehouses): boolean => {
      return warehouse.code === 'ecospace';
    })['@id']);
  }

  private async prepareBody(): Promise<any> {
    const toWarehouseId = this.warehouses.find((warehouse: IWarehouses): boolean => {
      return warehouse['@id'] === this.form.value.toWarehouse;
    }).id;

    const response: any = await this.warehouseResource.get(toWarehouseId)
      .pipe(takeUntil(this.destroyed$))
      .toPromise();

    const address = response.address;

    address.lastName = 'sweeek';
    address.firstName = 'sweeek';

    return {
      shippingAddress: address,
      billingAddress: address,
      locale: this.currentLocale,
      marketplace: 'showroom',
      orderType: 'repatriation',
      referralOrderSav: null,
      paymentType: 'free',
      sendPaymentLink: false,
      manuallyAdded: true,
      grcReason: null,
      estimate: false,
      zendeskTicketId: null,
      privateData: false,
    };
  }

  public async submit(): Promise<void> {
    if (!this.form.valid) {
      this.snackbar.warn(this.translate('ALERTS.ERROR.FORM'));

      return;
    }

    if (this.form.get('fromWarehouse').value !== null) {
      const preparedBody = await this.prepareBody();

      (<OrderResource>this.resource)
        .create(preparedBody, { entryPoint: '/v2/order-manager/manual-order-creation' })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response: any) => {
            this.createStockMovement(response.id);
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
          }
        );
    } else {
      this.createStockMovement(null, false);
      this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
    }
  }

  public createStockMovement(orderId: number|null = null, async: boolean = true): void {
    const formData: FormData = new FormData();
    const body: any = {
      fromWarehouse: '' !== this.form.value.fromWarehouse ? this.form.value.fromWarehouse : null,
      toWarehouse: this.form.value.toWarehouse,
      order: orderId ? HydraHelper.buildIri(orderId, 'orders') : null,
      type: 'repatriation'
    };
    if (this.form && this.form.value.file && this.form.value.file.subscriber) {
      this.form.value.file.subscriber.subscribe((fileList: IFileInfo[]) => {
        formData.append('file', <File>fileList[0].rawFile, fileList[0].name);
      });
    }

    this.stockMovementResource.create(body)
      .takeUntil(this.destroyed$)
      .subscribe((response: any): void => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        formData.append('businessObject', 'stock-movement-line');
        formData.append('businessObjectId', response.id);
        formData.append('countryCode', SessionHelper.getCountry().code);
        formData.append('locale', SessionHelper.getLocale());
        formData.append('async', (+async).toString());

        this.importResource.uploadFile(formData)
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            setTimeout((): void => {
              this.state.go('stock-movement.list');
            }, 100);
          })
        ;
      })
    ;
  }

  isReturnsOrStockDeals(): boolean {
    return ['returns', 'stock-deals'].includes(this.form.get('type').value);
  }

  filterWarehouses(value: any): void {
    const selectedId = (value as any)['@id'];
    this.form.get('toWarehouse').patchValue(null);
    this.fromWarehouse = [];

    switch (selectedId) {
      case 'returns':
        this.form.get('fromWarehouse').patchValue(null);
        this.toWarehouse = this.warehouses.filter((warehouse: IWarehouses) => warehouse.code === 'lsl');
        if (this.toWarehouse.length > 0) {
          this.form.get('toWarehouse').patchValue(this.toWarehouse[0]['@id']);
        }
        break;
      case 'stock-deals':
        this.form.get('fromWarehouse').patchValue(null);
        this.toWarehouse = this.warehouses.filter((warehouse: IWarehouses) => warehouse.code === 'alice_deals');
        if (this.toWarehouse.length > 0) {
          this.form.get('toWarehouse').patchValue(this.toWarehouse[0]['@id']);
        }
        break;
      default:
        this.toWarehouse = this.warehouses;
        this.fromWarehouse = this.warehouses;
        this.initDefaultValues();
        break;
    }
  }
}
