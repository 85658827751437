import { Component, ViewEncapsulation, AfterViewInit, ViewChild, TemplateRef, Inject, Input } from '@angular/core';
import { ReverseFormService } from './services';
import { FormErrorHelper } from '@helpers';
import { ReverseResource } from './resources';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { FileRestrictions, UploadEvent, SelectEvent, FileInfo, ClearEvent, RemoveEvent } from '@progress/kendo-angular-upload';
import {
  BASE_URL_API,
  FILE_EXTENSIONS,
  getReceiveStatuses,
  getReconditionedStatuses,
  getSelectableReconditionedStatuses,
  getPreselectionStatusesMapping,
  RECEIVE_WAITING,
  RECEIVE_SAV_WAITING,
} from '@constants';
import { SnackbarService } from '../snackbar';
import { AuthService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';
import { ICarrierGroup } from '@interfaces';
import { SkuSearchComponent } from '@components/generic/Form';
import { ReverseProductPackage } from './models';

const UPLOADER_REVERSE_ENDPOINT = `${BASE_URL_API}/file/reverse`;
const FILE_FIELD_NAME = 'file';

@Component({
  selector: 'app-reverse-case-detail',
  template: require('./reverse-case-detail.component.html'),
  styles: [require('./reverse-case-detail.component.scss')],
  encapsulation: ViewEncapsulation.None
})
export class ReverseCaseDetailComponent extends AbstractComponent implements AfterViewInit {

  @ViewChild('stateTemplate') public stateTemplate: TemplateRef<any>;
  @ViewChild('sizeTemplate') public sizeTemplate: TemplateRef<any>;
  @ViewChild(SkuSearchComponent) private skuSearchComponent: SkuSearchComponent;

  @Input() public readOnly: boolean = false;


  public uploaderEndpoint: string = UPLOADER_REVERSE_ENDPOINT;
  public fileRestrictions: FileRestrictions;
  public saveField: string = FILE_FIELD_NAME;
  public selectedFilesHeaders: ({ name: string; field: string; template?: undefined; } | { name: string; field: string; template: any; })[];
  public selectedFiles: any = [];
  public reconditionedStatus: object[] = getReconditionedStatuses();
  public selectableReconditionedStatus: object[] = getSelectableReconditionedStatuses();
  public receiveStatus: object[] = getReceiveStatuses();
  public receiveSavWaiting: string = RECEIVE_SAV_WAITING;
  public receiveWaiting: string = RECEIVE_WAITING;
  public isAddSku: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: ReverseResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public reverseFormService: ReverseFormService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);

    this.fileRestrictions = { allowedExtensions: FILE_EXTENSIONS };
  }

  ngAfterViewInit(): void {
    this.selectedFilesHeaders = [
      {
        name: this.translate('UPLOADER.NAME'),
        field: 'name',
      },
      {
        name: this.translate('UPLOADER.FILE_SIZE') + ' (Mo)',
        field: 'size',
        template: this.sizeTemplate
      },
      {
        name: this.translate('UPLOADER.STATUS'),
        field: 'state',
        template: this.stateTemplate
      }
    ];
  }

  /**
   * Creates the package header shown in package detail.
   *
   * @private
   * @param {AbstractControl} reverseProduct
   * @param {AbstractControl} reverseProductPackage
   * @returns {string}
   * @memberof ReverseCaseDetailComponent
   */
  private getPackageHeader(reverseProduct: AbstractControl, reverseProductPackage: AbstractControl): string {
    return `
        ${reverseProductPackage.get('packageSku').value}/${reverseProductPackage.get('packageNumber').value} -
        ${reverseProductPackage.get('receiveStatus').value}
        ${reverseProductPackage.get('reconditionedStatus').value ?
        `- ${reverseProductPackage.get('reconditionedStatus').value}` : ''}
      `;
  }

  /**
   * Fires by kendo-upload when one or more files are about to be uploaded.
   *
   * @param {UploadEvent} event
   */
  private onUpload(event: UploadEvent): void {
    event.data = {
      'name': event.files[0].name
    };
  }

  /**
   * Fires by kendo-upload when an `upload` operation has failed.
   * Fills form controls with errors.
   *
   * @param {event} event
   */
  private errorEventHandler(event: { [keys: string]: any }): void {
    FormErrorHelper.fillFormControlWithErrors(this.reverseFormService.reverseForm, event.response.errors);
  }

  /**
   * Fires by kendo-upload when an `upload` operation is successfully completed.
   * Averts operator when file upload success.
   * Adds the new file to the form.
   */
  private successEventHandler(event: { [keys: string]: any }, reverseProductPackage: AbstractControl): void {
    this.snackbar.validate(this.translate('DETAILS.UPLOAD_SUCCESS'));

    reverseProductPackage.get('pictures').value.push(event.response.body.url);
  }

  /**
   * Fires by kendo-upload when one or more files are selected.
   *
   * @param {SelectEvent} e
   * @memberof CustomerPhotosComponent
   */
  public selectEventHandler(e: SelectEvent) {
    let receivedFiles: FileInfo[] = [];

    if (e.files) {
      receivedFiles = e.files;
    }

    this.selectedFiles.push(...receivedFiles);
  }

  /**
   * Fires by kendo-upload when the clear button is clicked.
   *
   * @param {ClearEvent} e
   * @memberof CustomerPhotosComponent
   */
  public clearEventHandler(e: ClearEvent) {
    this.selectedFiles = [];
  }

  /**
   * Fires by kendo-upload when one or more files are removed.
   *
   * @param {RemoveEvent} e
   * @memberof CustomerPhotosComponent
   */
  public removeEventHandler(e: RemoveEvent) {
    this.selectedFiles = this.selectedFiles.filter((file: FileInfo) => file.uid !== e.files[0].uid);
  }

  /**
   * Calculates the number of items that are to be uploaded
   * https://www.telerik.com/kendo-angular-ui/components/upload/api/FileState/#toc-selected
   * All items that have a state different of selected and uploading are not considered.
   *
   * @returns {number}
   * @memberof ReverseCaseDetailComponent
   */
  public getTotalFilesRemaining(): number {
    return this.selectedFiles.filter((item: FileInfo) => item.state === 2 || item.state === 4).length;
  }

  public isExport(reverseProductPackage: FormGroup): boolean {
    return reverseProductPackage.get('reconditionedStatus').value === 'sage'
      && (reverseProductPackage.get('dateExportSage') !== undefined && reverseProductPackage.get('dateExportSage').value !== null);
  }
  private getCarrierGroups(carrierId: number): ICarrierGroup[] {
    const carriersOrders = [
      'autre',
      'relaiscolis-takeback-eco',
      'relaiscolis-takeback-confort',
      'relaiscolis-prmax',
      'relaiscolis-pr',
      'relaiscolis-eco',
      'relaiscolis-confort',
      'trusk_etage',
      'colissimo',
      'chronopost',
      'schenker',
      'gls_be_pco',
      'gls',
      'geodis',
      'dpd',
      'gls_fds',
    ];
    return this.reverseFormService.carrierGroups ? this.reverseFormService.carrierGroups.filter((carrierGroup: ICarrierGroup) => {
      return carrierGroup.active || carrierGroup.id === carrierId;
    }).sort((a, b) => carriersOrders.indexOf(b.code) - carriersOrders.indexOf(a.code)) : [];
  }

  private updateCarrier(event: number): void {
    const reverseProducts: FormArray = <FormArray>this.reverseFormService.reverseForm.get('reverseProducts');

    for (const i in reverseProducts.value) {
      if (reverseProducts.value.hasOwnProperty(i)) {
        const formGroup = reverseProducts.at(parseInt(i, 10)).get('reverseProductPackages') as FormArray;
        formGroup.controls.forEach((packageFG: FormGroup) => {
          if (!packageFG.value.carrierGroup) {
            packageFG.patchValue({ carrierGroup: event });
          }
        });
      }
    }
  }

  public isReconditionnedStatusShown(reverseProductPackage: FormGroup): boolean {
    return reverseProductPackage.get('expected').value &&
      reverseProductPackage.get('receiveStatus').value !== this.receiveWaiting &&
      reverseProductPackage.get('receiveStatus').value !== this.receiveSavWaiting;
  }

  /**
 * Adds a sku with packages to the reverse case (delegates treatment to reverseFormservice).
 */
  private addSku(): void {
    this.reverseFormService.getReverseProductPackages(this.skuSearchComponent.currentValues);
    this.skuSearchComponent.currentValues = [];
  }

  public getReceiveStatusesTitle(reverseProduct: FormGroup): string {
    const receiveStatuses = <{ id: string, label: string }[]>getReceiveStatuses();
    const states = reverseProduct.value.reverseProductPackages
      .map((reverseProductPackage: ReverseProductPackage) => reverseProductPackage.receiveStatus)
      .map((state: string) => {
        const findReceiveStatus = receiveStatuses.find((receiveStatus) => receiveStatus.id === state);
        return findReceiveStatus.label || '';
      })
      .filter((state: string) => !!state);
    if (states.length > 0) {
      return ` (${states.join(', ')})`;
    }
    return '';
  }

  public isAllReceiveStatusesWaiting(reverseProduct: FormGroup): string {
    const states = reverseProduct.value.reverseProductPackages.map((reverseProductPackage: ReverseProductPackage) => reverseProductPackage.receiveStatus);

    return states.every((state: string) => state === 'waiting');
  }

  private getReverseProductsPackages(i: number): FormArray {
    const reverseProducts: FormArray = <FormArray>this.reverseFormService.reverseForm.get('reverseProducts');

    return reverseProducts.at(i).get('reverseProductPackages') as FormArray;
  }

  private updateReceiveStatus(event: string, productIndex: number, packageIndex: number): void {
    if (undefined !== getPreselectionStatusesMapping()[event]) {
      this.getReverseProductsPackages(productIndex).at(packageIndex).patchValue({
        reconditionedStatus: getPreselectionStatusesMapping()[event]
      });
    }
  }

}
