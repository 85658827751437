import { AbstractResource } from './abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '../decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/scheduled_tasks',
  isHydra: false,
})

export class ScheduledTaskResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }
}
